import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
  0% {
      background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
      background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
      background-color: rgba(165, 165, 165, 0.1);
  }
`;

export default {
  Container: styled.div`
    width: 100%;
    height: 100%;
    flex-grow: 1;
    animation: ${pulse} 1s infinite ease-in-out;
    border-radius: 4px;
  `,
};
