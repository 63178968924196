import React, { useEffect, useState } from 'react';
import { Router, useRouter, withRouter } from 'next/router';
import startsWith from 'lodash/startsWith';
import compact from 'lodash/compact';
import head from 'lodash/head';
import last from 'lodash/last';
import map from 'lodash/map';
import isNaN from 'lodash/isNaN';
import Styled from './styles';
import convertToCamelCase, { POSTFIXS } from 'shared/helpers/convertToCamelCase';
import PAGES from 'shared/constants/PAGES';
import routes, { cleanURL } from 'shared/helpers/routes';

const timeoutMinutes = 10 * 1000 * 60;

const pages = [
  { route: routes.sales, page: PAGES.MY_SALES },
  { route: routes.purchases, page: PAGES.MY_PURCHASES },
  { route: routes.preorders, page: PAGES.PREORDERS },
  { route: routes.announcements, page: PAGES.ANNOUNCEMENT },
  { route: routes.announcement(1), page: PAGES.ANNOUNCEMENT_DETAIL },
  { route: routes.pool, page: PAGES.POOL },
  { route: routes.poolDetail(1), page: PAGES.POOL_DETAIL },
];

let timeout = null;

interface ReloadPageProps {
  router: Router;
  setDisabled: ({ name, value }: { name: string; value: boolean }) => void;
}

const getDisabledTableName = ({ asPath }) => {
  const pageName = compact(
    map(pages, (item, index) => {
      const lastCharacter = Number(last(asPath));

      if (startsWith(asPath, item.route)) {
        if (!isNaN(lastCharacter)) {
          return pages[index].page;
        }

        return item.page;
      }

      return null;
    }),
  );

  return convertToCamelCase({ str: head(pageName), postfix: POSTFIXS.TABLE_DISABLED });
};

const ReloadPage: React.FC<ReloadPageProps> = ({ setDisabled, router: { asPath } }) => {
  const [isVisible, setVisibile] = useState(false);
  const reloadPage = () => window.location.reload();
  const disabledTableName = getDisabledTableName({ asPath: cleanURL(asPath) });
  const disableTable = () => {
    setVisibile(true);
    setDisabled({ name: disabledTableName, value: true });
  };

  const router = useRouter();

  const checkMouseEvent = compact(pages.map((item) => startsWith(cleanURL(asPath), item.route))).length > 0;
  useEffect(() => {
    const onRouteChangeComplete = () => {
      setVisibile(false);
    };
    router.events.on('routeChangeComplete', onRouteChangeComplete);

    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [router.events]);

  useEffect(() => {
    const handleMouseMove = () => {
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        disableTable();
      }, timeoutMinutes);
    };

    if (checkMouseEvent) {
      timeout = setTimeout(() => {
        disableTable();
      }, timeoutMinutes);
      window.addEventListener('mousemove', handleMouseMove);
    }
    return () => {
      clearTimeout(timeout);
      window.removeEventListener('mousemove', handleMouseMove);
      setDisabled({ name: disabledTableName, value: false });
    };
  }, []);

  if (!isVisible) return null;

  return (
    <Styled.Container>
      Данные могли устареть, обновите страницу
      <Styled.ReloadButton onClick={reloadPage}>Обновить</Styled.ReloadButton>
    </Styled.Container>
  );
};

export default withRouter(ReloadPage);
