import styled, { css } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

interface ContainerProps {
  layoutSidebarIsOpen?: boolean;
  isVisible?: boolean;
  prevAppLayoutScrollTop?: boolean;
  offsetBottom?: boolean;
}

export default {
  Container: styled.button<ContainerProps>`
    position: fixed;
    z-index: 15;
    bottom: 64px;
    left: ${({ layoutSidebarIsOpen }) => (layoutSidebarIsOpen ? '264px' : '80px')};
    padding: 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background: none;
    border: 1px solid #e1e1e1;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    outline: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    opacity: 0;
    pointer-events: none;

    &:hover {
      opacity: 1;
    }

    /* isVisible */
    opacity: ${({ isVisible }) => isVisible && '0.88'};
    pointer-events: ${({ isVisible }) => isVisible && 'all'};

    /* prevAppLayoutScrollTop */
    ${({ prevAppLayoutScrollTop }) =>
      prevAppLayoutScrollTop &&
      css`
        & > svg {
          transform: rotate(180deg);
        }
      `}

    @media (max-width: ${BREAKPOINTS.XL}) {
      left: 16px;
      bottom: ${({ offsetBottom }) => (offsetBottom ? '88px' : '16px')};
      transform: none;
      transition: all 0.2s ease-in-out;
    }
  `,
};
