import styled from 'styled-components';
import MEDIA_QUERIES from 'shared/constants/MEDIA_QUERIES';

export default {
  Container: styled.div`
    position: fixed;
    left: 50%;
    bottom: 32px;
    z-index: 1000;
    transform: translateX(-50%);
    height: 40px;
    padding-left: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    background-color: #141414;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
    color: #fff;
    font-size: 14px;

    @media ${MEDIA_QUERIES.MOBILE} {
      left: 8px;
      right: 8px;
      transform: none;
      font-size: 12px;
    }
  `,

  ReloadButton: styled.button`
    position: relative;
    border: 0;
    background: none;
    margin: 0;
    margin-left: 12px;
    padding: 0 12px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 6px 6px 0;
    color: #ffda00;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.02em;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    outline: none;

    &:hover {
      color: #ffe652;
    }

    &:before {
      content: '';
      position: absolute;
      left: 2px;
      display: block;
      height: 23px;
      width: 1px;
      background-color: #303030;
    }
  `,
};
