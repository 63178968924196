import ScrollUp from 'components/ScrollUp';
import Head from 'next/head';
import React from 'react';
import { connect } from 'react-redux';
import IDS from 'shared/constants/IDS';
import ReloadPageContainer from '../ReloadPageContainer';
import Styled from './styles';

interface LayoutProps {
  title: string;
  children: any;
  disableLayout?: boolean;
  withoutPaddingsOnMobile?: boolean;
  isWhite?: boolean;
  page?: string;
  userAgent: string;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  title,
  disableLayout,
  isWhite,
  page,
  userAgent,
  withoutPaddingsOnMobile,
}) => (
  <Styled.Container
    withoutPaddingsOnMobile={withoutPaddingsOnMobile}
    disableLayout={disableLayout}
    isWhite={isWhite}
    id={IDS.APP_LAYOUT}
    userAgent={userAgent}
  >
    <Head>
      <title>{title}</title>
    </Head>
    <ScrollUp page={page} />
    <ReloadPageContainer />
    <Styled.PopupContainer id={IDS.POPUP_CONTAINER} />
    {children}
  </Styled.Container>
);

const mapStateToProps = (state: any) => ({
  userAgent: state.layout.userAgent,
});

export default connect(mapStateToProps)(Layout);
