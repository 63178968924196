import OS from 'shared/constants/OS';

const getOS = (userAgent: any) => {
  let currentOS;

  if (userAgent.indexOf('Windows NT 10.0') !== -1) currentOS = OS.WINDOWS_10;
  if (userAgent.indexOf('Windows NT 6.2') !== -1) currentOS = OS.WINDOWS_8;
  if (userAgent.indexOf('Windows NT 6.1') !== -1) currentOS = OS.WINDOWS_7;
  if (userAgent.indexOf('Windows NT 6.0') !== -1) currentOS = OS.WINDOWS_VISTA;
  if (userAgent.indexOf('Windows NT 5.1') !== -1) currentOS = OS.WINDOWS_XP;
  if (userAgent.indexOf('Windows NT 5.0') !== -1) currentOS = OS.WINDOWS_2000;
  if (userAgent.indexOf('Windows') !== -1) currentOS = OS.WINDOWS;
  if (userAgent.indexOf('Mac') !== -1) currentOS = OS.MAC;
  if (userAgent.indexOf('X11') !== -1) currentOS = OS.UNIX;
  if (userAgent.indexOf('Linux') !== -1) currentOS = OS.LINUX;

  return currentOS;
};

export default getOS;
