import React, { MouseEvent } from 'react';
import Placeholder from '../Placeholder';
import Styled, { StyledButtonProps } from './styles';

interface ButtonProps extends StyledButtonProps {
  onClick?: (event: MouseEvent<HTMLButtonElement>, data: any) => void;
  plain?: boolean;
  isLoading?: boolean;
  onlyIcon?: boolean;
  onlySmallIcon?: boolean;
  data?: any;
  children?: any;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  className?: string;
  isOpen?: boolean;
  mobilePadding?: string;
  title?: string;
}

const Button = ({
  children,
  onClick,
  data,
  plain,
  onlyIcon,
  onlySmallIcon,
  isLoading,
  ...rest
}: ButtonProps) => {
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(event, data);
    }
  };

  if (isLoading) {
    return (
      <Styled.Placeholder>
        <Placeholder />
      </Styled.Placeholder>
    );
  }

  return plain ? (
    <Styled.PlainButton onClick={handleClick} {...rest}>
      {children}
    </Styled.PlainButton>
  ) : (
    <Styled.Button onClick={handleClick} iconOnly={onlyIcon} smallIcon={onlySmallIcon} {...rest}>
      <Styled.Content>{children}</Styled.Content>
    </Styled.Button>
  );
};

export default Button;
