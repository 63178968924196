import styled, { css } from 'styled-components';
import { layoutScrollbar } from '../../app/styles/scrollbar';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';
import getOS from 'shared/helpers/getOS';
import OS from 'shared/constants/OS';

interface ContainerProps {
  isWhite?: boolean;
  userAgent?: string;
  disableLayout?: boolean;
  withoutPaddingsOnMobile?: boolean;
}

export default {
  Container: styled.div<ContainerProps>`
    position: relative;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background-color: ${({ isWhite }) => (isWhite ? '#fff' : null)};
    ${layoutScrollbar};

    ${({ userAgent, disableLayout }) => {
      const currentOS = getOS(userAgent);

      if (currentOS === OS.WINDOWS) {
        return css`
          padding: ${!disableLayout ? '16px 10px 0 16px' : null};

          ${() => css`
            @-moz-document url-prefix() {
              padding: ${!disableLayout ? '16px 6px 0 16px' : null};
            }
          `}
        `;
      }

      return css`
        padding: ${!disableLayout ? '16px 10px 0 16px' : null};

        ${() => css`
          @-moz-document url-prefix() {
            padding: ${!disableLayout ? '16px 24px 0 16px' : null};
          }
        `}
      `;
    }}

    @media (max-width: ${BREAKPOINTS.XL}) {
      padding: ${({ disableLayout }) => (disableLayout ? '0' : '24px 0')};

      ${(props) =>
        !props.withoutPaddingsOnMobile &&
        css`
          padding: 24px 0;
        `}

      &::-webkit-scrollbar {
        display: none;
      }
      &::-webkit-scrollbar-thumb {
        display: none;
      }
      &::-webkit-scrollbar-button {
        display: none;
      }
      &::-webkit-scrollbar-corner {
        display: none;
      }
    }
  `,

  PopupContainer: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1010;

    & > div,
    & > div > div {
      width: 100%;
      height: 100%;
    }

    @media (max-width: ${BREAKPOINTS.MD}) {
      /** range picker */
      .ant-calendar-range {
        width: 100%;
        height: 100%;
        overflow-y: auto;
      }

      .ant-calendar-range .ant-calendar-date-panel {
        display: flex;
        flex-direction: column;
      }

      .ant-calendar-range .ant-calendar-range-part {
        position: initial;
        width: 100%;
      }

      .ant-calendar-range .ant-calendar-input-wrap {
        width: 50%;
      }

      .ant-calendar-range-right .ant-calendar-input-wrap {
        position: absolute;
        top: 0;
        right: 0;
      }

      .ant-calendar-range-right .ant-calendar-input-wrap .ant-calendar-date-input-wrap {
        margin: 0;
      }

      .ant-calendar-range-middle {
        padding: 0;
      }

      /** datetime picker */
      .date-picker .ant-calendar {
        width: 100%;
        height: 100%;
        border-radius: 0;
      }

      .date-picker .ant-calendar-panel {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      .date-picker .ant-calendar-date-panel {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }

      .date-picker .ant-calendar-footer {
        margin-top: auto;
      }
    }
  `,
};
