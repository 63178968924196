export default {
  WINDOWS: 'Windows',
  WINDOWS_10: 'Windows 10',
  WINDOWS_8: 'Windows 8',
  WINDOWS_7: 'Windows 7',
  WINDOWS_VISTA: 'Windows Vista',
  WINDOWS_XP: 'Windows XP',
  WINDOWS_2000: 'Windows 2000',
  MAC: 'Mac/iOS',
  UNIX: 'UNIX',
  LINUX: 'Linux',
};
