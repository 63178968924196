import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { StyledLinkCSS } from '../Link/styles';
import COLORS from 'shared/constants/COLORS';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

export interface StyledButtonProps {
  iconOnly?: boolean;
  smallIcon?: boolean;
  center?: boolean;
  autoHeight?: boolean;
  nonePadding?: boolean;
  secondary?: boolean;
  ghost?: boolean;
  gray?: boolean;
  fluid?: boolean;
  small?: boolean;
  large?: boolean;
  smallPadding?: boolean;
  transparent?: boolean;
  opacity?: number;
  withoutBorder?: boolean;
  dangerHoverable?: boolean;
  styledDisabled?: boolean;
  isLink?: boolean;
  underline?: boolean;
  disableEvents?: boolean;
  transparentBlue?: boolean;
  primary?: boolean;
  danger?: boolean;
  dangerTransparent?: boolean;
  mobileSmall?: boolean;
}

interface StyledPlainButtonProps {
  danger?: boolean;
  opacity?: number;
}

export default {
  Button: styled.button<StyledButtonProps>`
    margin: ${(props) => (props.center ? '0 auto' : null)};
    height: ${(props) => (props.autoHeight ? 'auto' : '40px')};
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #ffda00;
    border-radius: 8px;
    border: 0;
    padding: ${(props) => (props.nonePadding ? 'none' : '0 25px')};
    font-size: 16px;
    color: #000;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    box-sizing: border-box;
    outline: none;

    &:disabled {
      opacity: 0.5 !important;
      cursor: not-allowed;
    }

    /* secondary */
    border: ${(props) => (props.secondary ? '1px solid #FFDA00' : null)};
    background-color: ${(props) => (props.secondary ? '#fff' : null)};

    /* ghost */
    border: ${(props) => (props.ghost ? '1px solid #E1E1E1' : null)};
    background-color: ${(props) => (props.ghost ? '#fff' : null)};

    /* gray */
    border: ${(props) => (props.gray ? '1px solid #E1E1E1' : null)};
    background-color: ${(props) => (props.gray ? '#E1E1E1' : null)};

    /* fluid */
    width: ${(props) => (props.fluid ? '100%' : null)};

    /* small */
    height: ${(props) => (props.small ? '32px' : null)};

    /* large */
    padding: ${(props) => (props.large ? '0 48px' : null)};

    /* iconOnly */
    height: ${(props) => (props.iconOnly ? '40px' : null)};
    width: ${(props) => (props.iconOnly ? '40px' : null)};
    min-width: ${(props) => (props.iconOnly ? '40px' : null)};
    padding: ${(props) => (props.iconOnly ? '0' : null)};

    /* onlySmallIcon */
    height: ${(props) => (props.smallIcon ? '32px' : null)};
    width: ${(props) => (props.smallIcon ? '32px' : null)};
    min-width: ${(props) => (props.smallIcon ? '32px' : null)};
    padding: ${(props) => (props.smallIcon ? '0' : null)};
    border-radius: ${(props) => (props.smallIcon ? '4px' : null)};

    padding: ${(props) => (props.smallPadding ? '0 10px' : null)};

    /* transparent */
    background-color: ${({ transparent }) => transparent && 'transparent'};

    &:focus {
      background-color: ${({ transparent }) => transparent && 'transparent'};
    }

    opacity: ${(props) => props.opacity || null};

    /* withoutBorder */

    border: ${(props) => (props.withoutBorder ? 0 : null)};

    &:hover:not(:disabled) {
      background-color: #ffe652;
      opacity: 1;

      /* secondary */
      background-color: ${(props) => (props.secondary ? '#fff6c2' : null)};

      /* ghost */
      background-color: ${(props) => (props.ghost ? '#e1e1e1' : null)};

      /* gray */
      background-color: ${(props) => (props.gray ? '#d7d7d7' : null)};

      /* transparent */
      background-color: ${({ transparent }) => transparent && '#f5f5f5'};
      opacity: ${({ transparent }) => transparent && 1};

      /* dangerHoverable */
      background-color: ${(props) => (props.dangerHoverable ? COLORS.PINK : null)};
    }

    &:active:not(:disabled) {
      background-color: #ffda00;
      opacity: 1;

      /* secondary */
      background-color: ${(props) => (props.secondary ? '#fff6c2' : null)};

      /* ghost */
      background-color: ${(props) => (props.ghost ? '#e1e1e1' : null)};

      /* gray */
      background-color: ${(props) => (props.gray ? '#e1e1e1' : null)};

      /* transparent */
      background-color: ${({ transparent }) => transparent && '#E1E1E1'};
      opacity: ${({ transparent }) => transparent && 1};

      /* dangerHoverable */
      background-color: ${(props) => (props.dangerHoverable ? COLORS.PINK : null)};
    }

    /* styledDisabled */
    opacity: ${(props) => (props.styledDisabled ? '0.5' : null)};
    cursor: ${(props) => (props.styledDisabled ? 'not-allowed' : null)};

    /* disableEvents */
    pointer-events: ${(props) => (props.disableEvents ? 'none' : null)};

    ${({ isLink }) =>
      isLink &&
      css`
        ${StyledLinkCSS};
        min-height: auto;
        padding: 0;
        height: auto;
        border: none;
        background: none;

        text-decoration: ${({ underline }: StyledButtonProps) => (underline ? 'underline' : 'none')};
        text-underline-position: under;

        /* danger */
        color: ${({ danger }: StyledButtonProps) => (danger ? COLORS.RED : null)};

        /* primary */
        color: ${({ primary }: StyledButtonProps) => (primary ? COLORS.BLUE : null)};

        &:hover:not(:disabled) {
          background: transparent;

          /* danger */
          color: ${({ danger }: StyledButtonProps) => (danger ? darken(0.15, COLORS.RED) : null)};
          /* primary */
          color: ${({ primary }: StyledButtonProps) => (primary ? darken(0.15, COLORS.BLUE) : null)};
        }

        &:focus {
          background: transparent;
        }
      `}

    ${({ transparentBlue, iconOnly }) =>
      transparentBlue &&
      !iconOnly &&
      css`
        padding: 0 8px;
        height: 32px;
        background-color: transparent;
        border-radius: 6px;
        color: #2f80ed;
        font-size: 14px;

        &:hover:not(:disabled) {
          background-color: #e6f0fd;
        }

        &:active:not(:disabled) {
          background-color: #cde1fb;
        }

        & svg {
          margin-left: 8px;
        }
      `}

    ${({ transparentBlue, iconOnly }) =>
      transparentBlue &&
      iconOnly &&
      css`
        fill: #2f80ed;
        background-color: transparent;

        &:hover:not(:disabled) {
          background-color: #e6f0fd;
        }

        &:active:not(:disabled) {
          background-color: #cde1fb;
        }
      `}

      ${({ danger }) =>
      danger &&
      css`
        background-color: ${COLORS.RED};
        color: #fff;

        &:hover:not(:disabled) {
          background-color: #fe8080;
        }
      `}

      ${({ dangerTransparent }) =>
      dangerTransparent &&
      css`
        background-color: transparent;
        color: ${COLORS.RED};

        &:hover:not(:disabled) {
          background-color: #fdebeb;
        }
      `}

    ${({ mobileSmall }) =>
      mobileSmall &&
      css`
        @media (max-width: ${BREAKPOINTS.XS}) {
          padding: 0 16px;
        }
      `}
  `,

  PlainButton: styled.button<StyledPlainButtonProps>`
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    border: 0;
    background: none;
    transition: all 0.2s ease-in-out;
    outline: none;
    cursor: pointer;

    ${({ danger }) =>
      danger &&
      css`
        color: ${COLORS.RED};
      `}

    opacity: ${({ opacity }) => opacity};

    &:hover:not(:disabled) {
      opacity: 1;
    }

    & > svg {
      margin-left: 8px;
    }
  `,

  Content: styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;
  `,

  Placeholder: styled.button`
    margin: 0;
    padding: 0;
    border: 0;
    background: none;
    outline: none;
    width: 100px;
    height: 40px;
    pointer-events: none;
  `,
};
