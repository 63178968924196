import { Router, withRouter } from 'next/router';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import IDS from 'shared/constants/IDS';
import PAGES from 'shared/constants/PAGES';
import ScrollUpIcon from '../../../static/icons/scroll-up.svg';
import Styled from './styles';

interface ScrollUpProps {
  selectedAnnouncements: any[];
  router: Router;
  layoutSidebarIsOpen: boolean;
  page?: string;
}

interface State {
  appLayoutScrollTop: number;
  prevAppLayoutScrollTop: number | null;
}

class ScrollUp extends Component<ScrollUpProps, State> {
  constructor(props: ScrollUpProps) {
    super(props);

    this.state = {
      appLayoutScrollTop: 0,
      prevAppLayoutScrollTop: null,
    };
  }

  componentDidMount() {
    const appLayout = document.getElementById(IDS.APP_LAYOUT);

    appLayout!.addEventListener('scroll', this.setScrollTop);
  }

  componentWillUnmount() {
    const appLayout = document.getElementById(IDS.APP_LAYOUT);

    appLayout!.removeEventListener('scroll', this.setScrollTop);
  }

  handleOnClick = () => {
    const appLayout = document.getElementById(IDS.APP_LAYOUT);
    const { prevAppLayoutScrollTop, appLayoutScrollTop } = this.state;

    if (prevAppLayoutScrollTop) {
      return this.setState(
        {
          prevAppLayoutScrollTop: null,
        },
        () => {
          appLayout!.scrollTo(0, prevAppLayoutScrollTop);
        },
      );
    }

    return this.setState(
      {
        prevAppLayoutScrollTop: appLayoutScrollTop,
      },
      () => {
        appLayout!.scrollTo(0, 0);
      },
    );
  };

  handleOnMouseLeave = () => {
    this.setState({
      prevAppLayoutScrollTop: null,
    });
  };

  setScrollTop = (event: any) => {
    this.setState({
      appLayoutScrollTop: event.target.scrollTop,
    });
  };

  render() {
    const { prevAppLayoutScrollTop, appLayoutScrollTop } = this.state;
    const {
      selectedAnnouncements,
      layoutSidebarIsOpen,
      page,
      router: { query },
    } = this.props;

    return (
      <Styled.Container
        isVisible={appLayoutScrollTop > 150 || Boolean(prevAppLayoutScrollTop)}
        prevAppLayoutScrollTop={Boolean(prevAppLayoutScrollTop)}
        onClick={this.handleOnClick}
        onMouseLeave={this.handleOnMouseLeave}
        offsetBottom={
          (selectedAnnouncements.length !== 0 && page === PAGES.POOL_DETAIL) ||
          (query.order && page === PAGES.POOL_DETAIL) ||
          false
        }
        layoutSidebarIsOpen={layoutSidebarIsOpen}
      >
        <ScrollUpIcon />
      </Styled.Container>
    );
  }
}

const mapStateToProps = (state: any) => ({
  selectedAnnouncements: state.selectedAnnouncements,
  layoutSidebarIsOpen: state.layout.sidebarIsOpen,
});

export default withRouter(connect(mapStateToProps)(ScrollUp)) as any;
